import { Box, Button, Container, Flex, Spinner, Text } from "@chakra-ui/react";
import DashboardHeader from "./DashboardHeader";
import Hexagon from "./Hexagon";
import { useEffect, useRef, useState } from "react";
import Drawer from "./Drawer";
import {
  useGetRarity,
  useGetUnclaimed,
  useSelectRandom,
} from "../utils/sats.api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedItems,
  setRandomSelectedItems,
} from "../store/slices/generalSlice";
import { MapInteractionCSS } from "react-map-interaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Countdown from "react-countdown";

function Dashboard({ onOpen }: any) {
  const size = 42;
  const margin = 0.8;
  const _f = 1.732 * size + 4 * margin - 1;

  const tabItems = [
    "all",
    "mythic",
    "legendary",
    "epic",
    "rare",
    "uncommon",
    "unclaimed",
  ];

  const dispatch = useDispatch();
  const { selectedItems, satStatus } = useSelector(
    (state: any) => state.general
  );
  const useFetchRaritySats = useGetRarity();
  const useFetchUnclaimedSats = useGetUnclaimed();
  const selectRandomSats = useSelectRandom();
  const startScrollRef = useRef<any>();
  const endScrollRef = useRef<any>();
  const [activeTab, setActiveTab] = useState("all");
  const [jumped, setJumped] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [start, setStart] = useState(0);
  const [raritySats, setRaritySats] = useState<any>({
    sat_minted: [],
    sat_numbers: [],
  });
  const [zoom, setZoom] = useState<any>({
    scale: 1,
    translation: { x: 0, y: 0 },
  });

  const cartTimer = localStorage?.getItem("cartTimer");

  const renderer = ({ minutes, seconds }: any) => {
    // Render a countdown
    return (
      <Text color="#fff" fontSize="12px">
        0{minutes}:{seconds > 9 ? seconds : "0" + seconds}
      </Text>
    );
  };

  const generateRandomNumbers = (random: number) => {
    selectRandomSats
      ?.mutateAsync(random)
      ?.then(async (res: any) => {
        dispatch(setRandomSelectedItems(res?.data));
      })
      .catch((err: any) => {
        toast.error(err?.message);
      });
  };

  const getRaritySats = async () => {
    await useFetchRaritySats
      ?.mutateAsync({ rarity: activeTab, start: start })
      .then((res: any) => {
        setRaritySats(res?.data);
      })
      .catch((err: any) => {
        toast.warn(err || "Something went wrong. Please try again!");
      });
  };

  const getUnclaimedSats = async () => {
    await useFetchUnclaimedSats
      ?.mutateAsync({ start })
      .then((res: any) => {
        if (res?.data?.sat_numbers) {
          setRaritySats((prev: any) => ({
            sat_minted: [],
            sat_numbers: res?.data?.sat_numbers,
          }));
        } else {
          toast.warn("No more blocks to show!");
        }
      })
      .catch((err: any) => {
        toast.warn(err || "Something went wrong. Please try again!");
      });
  };

  const checkIsMinted = (idx: any) => {
    if (raritySats?.sat_minted?.[idx] == 1) {
      return true;
    } else {
      return false;
    }
  };

  const moveToScroll = (where: string) => {
    setZoom({
      scale: 1,
      translation: { x: 0, y: 0 },
    });
    setJumped(!jumped);
    if (where === "last") {
      endScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      startScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (activeTab === "unclaimed") {
      getUnclaimedSats();
    } else {
      getRaritySats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, start]);

  useEffect(() => {
    if (selectedItems?.length === 0) {
      localStorage.removeItem("cartTimer");
    }
  }, [selectedItems]);

  useEffect(() => {
    if (Number(satStatus?.unclaimed_start) !== 0) {
      setStart(Number(satStatus?.unclaimed_start));
    } else {
      setStart(0);
    }
  }, [satStatus]);

  return (
    <Box>
      <Container
        maxW="1720px"
        background="rgba(255, 255, 255, 0.15)"
        p="0"
        mb="25px"
        pos="relative"
        borderRadius="8px"
        mt={{ base: "25px", xl: "30px" }}
      >
        {cartTimer && (
          <Flex
            maxW="190px"
            mx="auto"
            justify="center"
            bg="rgba(255, 255, 255, 0.15)"
            align="center"
            gap="10px"
          >
            <Text color="#fff" fontSize="12px">
              Clearing cart in
            </Text>
            <Countdown
              date={JSON.parse(cartTimer) + 300 * 1000}
              renderer={renderer}
              onComplete={() => {
                toast.error("Cart cleared, Please add blocks again!");
                localStorage.removeItem("cartTimer");
                dispatch(clearSelectedItems([]));
              }}
            />
          </Flex>
        )}
        <DashboardHeader
          setShowDrawer={setShowDrawer}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabItems={tabItems}
          setStart={setStart}
          setRaritySats={setRaritySats}
          moveToScroll={moveToScroll}
        />
        <Box
          minH="calc(100vh - 270px)"
          maxH="calc(100vh - 270px)"
          maxW="100%"
          overflow="hidden"
        >
          {activeTab === "legendary" ? (
            <Text color="#fff" textAlign="center">
              The first Legendary block is set to be mined in 2032
            </Text>
          ) : useFetchRaritySats?.isLoading ||
            useFetchUnclaimedSats?.isLoading ? (
            <Flex justify="center">
              <Spinner size="lg" color="#fff" />
            </Flex>
          ) : raritySats?.sat_numbers?.length > 0 ? (
            <MapInteractionCSS defaultValue={zoom}>
              <Flex mb="25px">
                <Box
                  fontSize="0"
                  _before={{
                    content: `""`,
                    width: `calc(${size}px/2 + ${margin}px)`,
                    float: "left",
                    height: "120%",
                    shapeOutside: `repeating-linear-gradient(     
                                   #0000 0 calc(${_f}px - 3px),      
                                 #000  0 ${_f}px)`,
                  }}
                >
                  {raritySats?.sat_numbers?.map((number: any, idx: number) => {
                    return (
                      <Hexagon
                        idx={idx}
                        number={number?.toString()}
                        checkIsMinted={checkIsMinted}
                        s={size}
                        m={margin}
                        f={_f}
                        scrollRef={
                          idx === 0
                            ? startScrollRef
                            : idx === raritySats?.sat_numbers?.length - 1
                            ? endScrollRef
                            : null
                        }
                      />
                    );
                  })}
                </Box>
              </Flex>
            </MapInteractionCSS>
          ) : (
            <Flex justify="center" gap="10px">
              <Text color="#fff">No More Blocks to Show</Text>
              {Number(start) > 0 && (
                <Text
                  color="#B206F9"
                  cursor="pointer"
                  textDecor="underline"
                  onClick={() => setStart(start - 2000)}
                >
                  Go Back
                </Text>
              )}
            </Flex>
          )}
          {raritySats?.sat_numbers?.length > 999 && (
            <Flex pos="fixed" bottom="0" margin="10px" gap="10px">
              {start !== 0 && (
                <Button
                  size={{ base: "md" }}
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  borderRadius="6px"
                  color="#fff"
                  display="flex"
                  gap="8px"
                  alignItems="center"
                  _hover={{
                    bg: "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)",
                    border: "1px solid #fff",
                  }}
                  _disabled={{
                    opacity: "0.5",
                  }}
                  isDisabled={
                    useFetchRaritySats?.isLoading ||
                    useFetchUnclaimedSats?.isLoading
                  }
                  onClick={() => {
                    setStart(start - 2000);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleChevronLeft} /> Prev{" "}
                </Button>
              )}
              {raritySats?.sat_numbers?.length > 999 && (
                <Button
                  ml={start === 0 ? "110px" : "0px"}
                  size={{ base: "md" }}
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  borderRadius="6px"
                  color="#fff"
                  display="flex"
                  gap="8px"
                  alignItems="center"
                  _hover={{
                    bg: "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)",
                    border: "1px solid #fff",
                  }}
                  _disabled={{
                    opacity: "0.5",
                  }}
                  isDisabled={
                    useFetchRaritySats?.isLoading ||
                    useFetchUnclaimedSats?.isLoading
                  }
                  onClick={() => {
                    setStart(start + 2000);
                  }}
                >
                  Next <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                </Button>
              )}
            </Flex>
          )}
        </Box>
      </Container>
      {raritySats?.sat_numbers?.length > 0 && !showDrawer && (
        <Box
          pos={{ base: "fixed", md: "absolute" }}
          zIndex="1000"
          bottom={{ base: "10", md: "auto" }}
          marginBottom="20px"
          left="50%"
          top={{ base: "auto", md: "65%" }}
          transform="translateX(-50%)"
        >
          {(activeTab === "all" ||
            activeTab === "uncommon" ||
            activeTab === "unclaimed") && (
            <Flex gap="10px">
              {raritySats?.sat_numbers?.length > 10 &&
                selectedItems?.length <= 190 && (
                  <Button
                    height={{ base: "42px", md: "52px" }}
                    width="100%"
                    p="10px"
                    bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    borderRadius="6px"
                    boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.27)"
                    color="#fff"
                    fontSize={{ base: "14px", md: "18px" }}
                    fontWeight="600"
                    isDisabled={selectRandomSats?.isLoading}
                    isLoading={selectRandomSats?.isLoading}
                    _hover={{
                      background: "#AC00FD",
                      border: "2px solid #CD23E9",
                      boxShadow: "none",
                    }}
                    onClick={() => generateRandomNumbers(10)}
                  >
                    Add Random 10
                  </Button>
                )}
              {raritySats?.sat_numbers?.length > 50 &&
                selectedItems?.length <= 150 && (
                  <Button
                    width="100%"
                    height={{ base: "42px", md: "52px" }}
                    p="10px"
                    bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    borderRadius="6px"
                    boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.27)"
                    color="#fff"
                    fontSize={{ base: "14px", md: "18px" }}
                    fontWeight="600"
                    isDisabled={selectRandomSats?.isLoading}
                    isLoading={selectRandomSats?.isLoading}
                    _hover={{
                      background: "#AC00FD",
                      border: "2px solid #CD23E9",
                      boxShadow: "none",
                    }}
                    onClick={() => generateRandomNumbers(50)}
                  >
                    Add Random 50
                  </Button>
                )}
            </Flex>
          )}
          {selectedItems?.length > 0 && (
            <Flex mt="6px" align="center" gap="10px">
              <Button
                color="#fff"
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight="600"
                borderRadius="6px"
                p="10px 20px"
                height={{ base: "42px", md: "52px" }}
                bg="#533685"
                boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.27)"
                _hover={{
                  background: "#533685",
                  border: "2px solid #CD23E9",
                  boxShadow: "none",
                }}
                onClick={() => {
                  setShowDrawer(true);
                }}
              >
                Inscribe {selectedItems?.length}
              </Button>
              <Button
                color="#fff"
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight="600"
                borderRadius="6px"
                p="10px 20px"
                height={{ base: "42px", md: "52px" }}
                bg="#533685"
                boxShadow="0px 4px 34px 0px rgba(134, 77, 161, 0.27)"
                _hover={{
                  background: "#533685",
                  border: "2px solid #CD23E9",
                  boxShadow: "none",
                }}
                onClick={() => {
                  dispatch(clearSelectedItems([]));
                  toast.warn("Cart cleared!");
                }}
              >
                Cancel
              </Button>
            </Flex>
          )}
        </Box>
      )}
      {showDrawer && (
        <Drawer
          setShowDrawer={setShowDrawer}
          getRaritySats={getRaritySats}
          onOpen={onOpen}
        />
      )}
    </Box>
  );
}

export default Dashboard;
