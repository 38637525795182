import { Text } from "@chakra-ui/react";
import React from "react";

function GradientColor(props: any) {
  return (
    <Text
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      fontStyle="normal"
      bgGradient="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
      bgClip="text"
    >
      {props.title}
    </Text>
  );
}

export default GradientColor;
