import { Box, useDisclosure } from "@chakra-ui/react";
import Header from "../components/Header";
import HolderPage from "../components/HolderPage";

function Holders() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box px="20px">
      <Header isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <HolderPage />
    </Box>
  );
}

export default Holders;
