import { Container, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MyItemsFilter from "./MyItemsFilter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetMyMints } from "../utils/sats.api";
import { toast } from "react-toastify";
import NFTCard from "./NFTCard";
import { satbox } from "../utils/box/newSat";
import GradientColor from "./GradientColor";
import { IconGift } from "@tabler/icons-react";

function MyItems(props: any) {
  const fetchMyMints = useGetMyMints();
  const navigate = useNavigate();
  const { activeAccount } = useSelector((state: any) => state?.general);
  const tabItems = ["all", "mythic", "legendary", "epic", "rare", "uncommon"];
  const [activeTab, setActiveTab] = useState("all");
  const [myMints, setMyMints] = useState([]);
  const [myPoints, setMyPoints] = useState(0);

  const filteredMints = (currentTab: string) => {
    if (myMints?.length > 0) {
      return myMints?.filter((mint: any) => {
        const rarityBox = satbox(mint);
        let rarity = rarityBox?.rarity();
        switch (currentTab) {
          case "all":
            return rarityBox?.value?.toString();
          case "mythic":
            return rarity?.toLowerCase() === currentTab;
          case "legendary":
            return rarity?.toLowerCase() === currentTab;
          case "epic":
            return rarity?.toLowerCase() === currentTab;
          case "rare":
            return rarity?.toLowerCase() === currentTab;
          case "uncommon":
            return rarity?.toLowerCase() === currentTab;
          default:
            return rarityBox?.value?.toString();
        }
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (activeAccount?.address) {
      fetchMyMints
        ?.mutateAsync(activeAccount?.address)
        .then((res: any) => {
          setMyPoints(res?.data?.points);
          setMyMints(res?.data?.mint_sats);
        })
        .catch((err: any) => {
          toast.error(err?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  return (
    <Container
      maxW="1720px"
      background="rgba(255, 255, 255, 0.15)"
      p="20px"
      mb="25px"
      pos="relative"
      borderRadius="8px"
      mt={{ base: "35px", xl: "80px" }}
    >
      <Flex
        px={{ base: "8px", md: "20px" }}
        gap={{ base: "16px", sm: "24px" }}
        align="center"
        flexDir={{ base: "column", sm: "row" }}
      >
        <Flex align="center" gap="16px">
          <GradientColor
            title="My Rune Points"
            fontSize="18px"
            fontWeight="600"
          />
          <Text
            fontWeight="500"
            fontSize="18px"
            fontStyle="normal"
            color="#fff"
          >
            {myPoints}
          </Text>
        </Flex>
        <IconGift
          onClick={props?.onBonusOpen}
          cursor="pointer"
          color="#CC22E8"
        />
      </Flex>

      <MyItemsFilter
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabItems={tabItems}
      />
      <Flex
        wrap="wrap"
        gap="10px"
        mt="20px"
        justify={{ base: "center", md: "left" }}
      >
        {activeAccount?.address ? (
          fetchMyMints?.isLoading ? (
            <Spinner size="md" color="#fff" mx="auto" />
          ) : filteredMints(activeTab)?.length > 0 ? (
            filteredMints(activeTab)?.map((mint, idx: number) => {
              const satNumber = satbox(mint);
              return (
                <NFTCard
                  s={satNumber?.value}
                  onClick={() => navigate(`/details/${mint}`)}
                />
              );
            })
          ) : activeTab === "all" ? (
            <Text color="#fff" textAlign="center" mx="auto">
              You don't have any mints yet.
            </Text>
          ) : (
            <Text color="#fff" textAlign="center" mx="auto">
              You don't have {activeTab} mints yet.
            </Text>
          )
        ) : (
          <Text color="#fff" mx="auto">
            Please connect wallet to see your mints
          </Text>
        )}
      </Flex>
    </Container>
  );
}

export default MyItems;
