import { Box, useDisclosure } from "@chakra-ui/react";
import Header from "../components/Header";
import MyItems from "../components/MyItems";

function MyItemsPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBonus,
    onOpen: onBonusOpen,
    onClose: onBonusClose,
  } = useDisclosure();
  return (
    <Box px="20px">
      <Header
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isBonus={isBonus}
        onBonusClose={onBonusClose}
      />
      <MyItems onBonusOpen={onBonusOpen} />
    </Box>
  );
}

export default MyItemsPage;
