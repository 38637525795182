import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SatDetails from "./pages/SatDetails";
import Holders from "./pages/Holders";
import MyItemsPage from "./pages/MyItems";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/holders" element={<Holders />} />
      <Route path="/my-mints" element={<MyItemsPage />} />
      <Route path="/details/:id" element={<SatDetails />} />
    </Routes>
  );
}

export default App;
