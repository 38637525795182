import { Box, Flex, Image, Text } from "@chakra-ui/react";
import CancelIco from "../assets/images/cancel-ico.svg";
import { useDispatch } from "react-redux";
import { setSelectedItems } from "../store/slices/generalSlice";
import { satbox } from "../utils/box/newSat";

function CartListItem(props: any) {
  const dispatch = useDispatch();
  const satNumber = satbox(props?.number);
  return (
    <Flex
      key={props.idx}
      bg="#301E46"
      borderRadius="4px"
      width="100%"
      height="50px"
      p="14px 24px"
      align="center"
      justify="space-between"
    >
      <Text color="#a98ed6" fontSize="18px" fontWeight="400">
        {satNumber?.value?.toString()}
      </Text>
      <Box
        cursor="pointer"
        onClick={() => {
          dispatch(setSelectedItems(props.number?.toString()));
        }}
      >
        <Image src={CancelIco} alt="cancel" />
      </Box>
    </Flex>
  );
}

export default CartListItem;
