import { Box, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

function InfoCard(props: any) {
  const { satStatus } = useSelector((state: any) => state.general);

  const checkSupply = (name: any) => {
    const lowerName = name?.toLowerCase() + "_supply";
    return satStatus?.[lowerName];
  };

  return (
    <Box
      bg="rgba(24, 5, 48, 0.90)"
      p="20px"
      width="100%"
      maxW="588px"
      position="absolute"
      zIndex="10000"
      top="0"
      right="0"
      marginTop="80px"
      marginRight="20%"
    >
      {props?.Items?.map((item: any, idx: number) => (
        <>
          <Flex
            align="center"
            justify="space-between"
            gap="10px"
            mt="6px"
            key={idx}
          >
            <Text
              color="#A98ED6"
              fontSize="18px"
              fontWeight="500"
              width="100%"
              maxW="130px"
            >
              {item?.name}
            </Text>
            <Box
              width="100%"
              maxW="386px"
              height="35px"
              border="1px solid #CD23E9"
              borderTopEndRadius="12px"
              p="2px"
              display={{ base: "none", sm: "block" }}
            >
              <Box
                bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                width={
                  (Number(checkSupply(item?.name) || 0) / Number(item?.total)) *
                    100 +
                  "%"
                }
                height="100%"
                borderTopEndRadius="10px"
              ></Box>
            </Box>
            <Flex w="100%" maxW="220px" justify="end">
              <Text color="#A77EDA" fontSize="16px" fontWeight="500">
                {checkSupply(item?.name) || 0}
              </Text>
              <Text color="#fff" fontSize="16px" fontWeight="500">
                /
              </Text>
              <Text color="#fff" fontSize="16px" fontWeight="500">
                {item?.total}
              </Text>
            </Flex>
          </Flex>
          <Box
            mt="10px"
            mb="15px"
            width="100%"
            maxW="386px"
            height="35px"
            border="1px solid #CD23E9"
            borderTopEndRadius="12px"
            p="2px"
            display={{ base: "block", sm: "none" }}
          >
            <Box
              bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              width={
                (Number(checkSupply(item?.name) || 0) / Number(item?.total)) *
                  100 +
                "%"
              }
              height="100%"
              borderTopEndRadius="10px"
            ></Box>
          </Box>
        </>
      ))}
    </Box>
  );
}

export default InfoCard;
