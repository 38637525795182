import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PageTab({ title, path }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      <Text
        fontSize="20px"
        fontWeight={location?.pathname === path ? "700" : "500"}
        color={"#715693"}
        background={
          location?.pathname === path
            ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
            : ""
        }
        bgClip={location?.pathname === path ? "text" : ""}
        textTransform="capitalize"
        cursor="pointer"
        onClick={() => navigate(path)}
      >
        {title}
      </Text>
      {location?.pathname === path && (
        <Box
          mt="5px"
          width="100%"
          height="5px"
          borderRadius="20px"
          bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
        ></Box>
      )}
    </Box>
  );
}

export default PageTab;
