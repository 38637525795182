import { Text } from "@chakra-ui/react";

function Label(props: any) {
  return (
    <Text fontSize="18px" fontWeight="400" color="#fff">
      {props.title}
    </Text>
  );
}

export default Label;
