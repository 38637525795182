import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import ViewIco from "../assets/images/eye-ico.svg";
import ExternalIco from "../assets/images/external-ico-primary.svg";
import Label from "../components/Label";
import Value from "../components/Value";
import { useEffect, useState } from "react";
import { useGetSat } from "../utils/sats.api";
import { toast } from "react-toastify";
import NFTCard from "../components/NFTCard";
import satBox from "../utils/box/satBoxRarity";
import { satbox } from "../utils/box/newSat";

function SatDetails() {
  const useGetSatDetails = useGetSat();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [satNumber, setSatNumber] = useState<any>("");
  const [isSatSet, setIsSatSet] = useState<boolean>(false);
  const [satDetails, setSatDetails] = useState<any>(null);
  const [satInfo, setSatInfo] = useState<any>(null);
  const getSatDetails = async () => {
    const satN = satbox(id);
    await useGetSatDetails
      .mutateAsync({ sat: satN?.value?.toString() })
      .then((res: any) => {
        setSatDetails(res?.data);
      })
      .catch((err: any) => {
        toast.warn(
          err || `Something went wrong while getting ${id}.sat details `
        );
      });
  };

  const checkSatInfo = (s: any) => {
    const satN = satbox(s);
    setSatNumber(satN?.value);
    setIsSatSet(true);
    const satRarity = satBox().default;
    const sat = new satRarity(satN?.value);
    var { rarity, emoji, color } = sat?.rarityEmoji();
    setSatInfo({
      rarity,
      emoji,
      color,
    });
  };

  useEffect(() => {
    if (Number(id) > 2099999997689999) {
      navigate("/");
    } else {
      checkSatInfo(id);
      getSatDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box px="20px">
      <Header isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <Container maxW="1700px" mt="30px" mb="50px">
        <Flex
          align="center"
          justify="center"
          gap="20"
          flexDir={{ base: "column", lg: "row" }}
        >
          {isSatSet && <NFTCard s={satNumber} onClick={() => {}} />}
          <Box minW={{ base: "280px", md: "410px" }} w="100%">
            <Text
              fontSize={{ base: "18px", md: "2vw" }}
              fontWeight="600"
              bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              bgClip="text"
            >
              {satNumber?.toString()}
            </Text>

            <Flex
              mt="50px"
              gap="36px"
              justify="space-between"
              flexDir={{ base: "column", sm: "row" }}
              align="center"
            >
              <Box w="100%">
                <Text color="#CDCDCD" fontSize="24px" fontWeight="600">
                  Deed Info
                </Text>
                <Box bg="#1A1919" p="12px 20px" mt="32px">
                  <Label title="Rarity" />
                  <Flex>
                    <Flex
                      mt="5px"
                      bg={`${satInfo?.color}7e`}
                      borderRadius="4px"
                      p="5px 10px"
                      gap="8px"
                    >
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color={satInfo?.color}
                      >
                        {satInfo?.rarity || "N/A"}
                      </Text>
                      <Text color={satInfo?.color}>{satInfo?.emoji}</Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box p="12px 20px">
                  <Label title="Cycle" />
                  <Value value={satDetails?.cycle || 0} />
                </Box>
                <Box bg="#1A1919" p="12px 20px">
                  <Label title="Epoch" />
                  <Value value={satDetails?.epoch || 0} />
                </Box>
                <Box p="12px 20px">
                  <Label title="Period" />
                  <Value value={satDetails?.period || 0} />
                </Box>
                <Box bg="#1A1919" p="12px 20px">
                  <Label title="Block" />
                  <Value value={satDetails?.block || 0} />
                </Box>
              </Box>
              <Box width="100%">
                <Flex
                  bg="#171717"
                  borderRadius="4px"
                  maxW="131px"
                  gap="9px"
                  p="10px 16px"
                  cursor="pointer"
                  onClick={() => {
                    window?.open(
                      `https://ordinals.com/sat/${satDetails?.degree}`,
                      "_blank"
                    );
                  }}
                >
                  <Text color="#cdcdcd" fontSize="20px" fontWeight="600">
                    Sat
                  </Text>
                  <Image src={ExternalIco} alt="link" />
                </Flex>
                <Box bg="#1A1919" p="18px 20px" mt="20px">
                  <Label title="Degree" />
                  <Text
                    bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    bgClip="text"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    {satDetails?.degree || "N/A"}
                  </Text>
                </Box>
                <Box p="12px 20px">
                  <Label title="Name" />
                  <Value value={satDetails?.name || "N/A"} />
                </Box>
                <Box bg="#1A1919" p="12px 20px">
                  <Label title="Decimal" />
                  <Value value={satDetails?.decimal || "N/A"} />
                </Box>
                <Box p="12px 20px">
                  <Label title="Offset" />
                  <Value value={satDetails?.offset || "N/A"} />
                </Box>
                <Box bg="#1A1919" p="12px 20px">
                  <Label title="Percentile" />
                  <Value value={satDetails?.percentile || "N/A"} />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex justify="center">
          <Button
            mt="25px"
            p="10px"
            border="1px solid #533685"
            borderRadius="136px"
            height="60px"
            minW="216px"
            maxW="450px"
            width="100%"
            bg="transparent"
            color="#533685"
            _hover={{
              background: "#533685",
              color: "#fff",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Image src={ViewIco} alt="view" />
            <Text fontSize="18px" fontWeight="600">
              View Runebox
            </Text>
          </Button>
        </Flex>
      </Container>
    </Box>
  );
}

export default SatDetails;
