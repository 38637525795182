const getAddressSize = (address: string) => {
  // len size  =  // 20 = 12
  let addrSize = 25 + 1; // p2pkh

  if (address?.indexOf("bc1q") == 0 || address?.indexOf("tb1q") == 0) {
    addrSize = 22 + 1; // 42 p2wpkh
  } else if (address?.indexOf("bc1p") == 0 || address?.indexOf("tb1p") == 0) {
    addrSize = 34 + 1; // 62 p2tr
  } else if (address?.indexOf("2") == 0 || address?.indexOf("3") == 0) {
    addrSize = 23 + 1; // p2sh
  }
  return addrSize;
};

export async function estimateNetworkFee(params: {
  sats: string[];
  balance: number;
  feeRate: number;
  receiveAddresses: string;
}) {
  const { sats, feeRate, balance, receiveAddresses } = params;
  let opCount = sats.length;
  const balances = opCount * balance;
  const outputSize = getAddressSize(receiveAddresses);
  let vSize = Math.ceil(116 + sats[0].length + outputSize);
  if (opCount > 0) {
    let inputSize = 100;
    sats.forEach((sat) => {
      inputSize += sat.length + 28;
    });
    vSize = Math.ceil(inputSize + outputSize * opCount);
  }
  return Math.ceil(vSize * feeRate) + balances;
}

export const findRarityBg = (name: string) => {
  switch (name) {
    case "Uncommon":
      return "#228b22";
    case "Legendary":
      return "#ffd700";
    case "Mythic":
      return "#f2a900";
    case "Epic":
      return "#9932cc";
    case "Rare":
      return "#6495ed";
    default:
      return "#3A3933";
  }
};

export const findEmoji = (name: string) => {
  switch (name) {
    case "Uncommon":
      return "🌱";
    case "Legendary":
      return "🌝";
    case "Epic":
      return "🪻";
    case "Rare":
      return "🧿";
    case "Mythic":
      return "👑";
    case "Common":
      return "🪙";
    default:
      return "";
  }
};
