import { Box, Image, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import SearchIco from "../assets/images/search-ico.svg";

function SearchBlock() {
  const [searchBlock, setSearchBlock] = useState("");

  return (
    <Box
      minW={{ base: "120px", md: "357px" }}
      pos="relative"
      background={{ base: "#000", xl: "#312F2F" }}
      borderRadius="8px"
      border="1px solid #312F2F"
    >
      <Input
        type="number"
        placeholder="Search Runebox E.g.: 0"
        padding={{ base: "17px 14px", md: "17px 0px 17px 24px" }}
        color="#AFA9A9"
        fontSize="14px"
        fontWeight="400"
        border="0"
        minH="52px"
        minW={{ base: "150px", md: "257px" }}
        width="80%"
        focusBorderColor="transparent"
        _placeholder={{
          color: "#AFA9A9",
          fontSize: "14px",
          fontWeight: "400",
        }}
        value={searchBlock}
        onChange={(e: any) => {
          setSearchBlock(e.target.value);
        }}
      />

      <Box
        cursor="pointer"
        pos="absolute"
        right="0"
        top="0"
        marginTop="15px"
        marginRight="15px"
        onClick={() => {
          if (Number(searchBlock) > 2099999997689999) {
            toast.warn("Number is out of Range, Please try another one.");
          } else {
            window.open(`/details/${searchBlock}`, "_blank");
            setSearchBlock("");
          }
        }}
      >
        <Image src={SearchIco} alt="search" />
      </Box>
    </Box>
  );
}

export default SearchBlock;
