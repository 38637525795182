import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface InitState {
  activeAccount: any;
  selectedItems: string[];
  satStatus: any;
}

const initialState: InitState = {
  activeAccount: null,
  selectedItems: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") || "")
    : [],
  satStatus: null,
};
export const generalSlice = createSlice({
  name: "general",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setActiveAccount: (state, action) => {
      state.activeAccount = action?.payload;
    },
    setSatStatus: (state, action) => {
      state.satStatus = action?.payload;
    },
    setSelectedItems: (state, action): any => {
      if (state?.selectedItems?.length === 0) {
        toast.warn("Cart will be cleared in 5 minutes.");
        localStorage.setItem("cartTimer", JSON.stringify(Date.now()));
      }
      if (state?.selectedItems?.includes(action.payload)) {
        let filteredArr = state?.selectedItems?.filter(
          (temp: any) => temp !== action?.payload
        );
        state.selectedItems = filteredArr;
        if (filteredArr?.length > 0) {
          localStorage.setItem("cart", JSON.stringify(filteredArr));
        } else {
          localStorage?.removeItem("cartTimer");
          localStorage?.removeItem("cart");
        }
      } else {
        toast.success("New block added to the cart.", { autoClose: 1000 });
        let tempArr = [...state?.selectedItems];
        tempArr.push(action?.payload);
        state.selectedItems = tempArr;
        localStorage.setItem("cart", JSON.stringify(tempArr));
      }
    },
    setRandomSelectedItems: (state, action): any => {
      if (state?.selectedItems?.length === 0) {
        toast.warn("Cart will be cleared in 5 minutes.");
        localStorage.setItem("cartTimer", JSON.stringify(Date.now()));
      }
      toast.success("New block(s) added to the cart.", { autoClose: 1000 });
      let tempArr = [...state?.selectedItems, ...action?.payload];
      state.selectedItems = tempArr;
      localStorage.setItem("cart", JSON.stringify(tempArr));
    },
    clearSelectedItems: (state, action): any => {
      state.selectedItems = action.payload;
      localStorage?.removeItem("cartTimer");
      localStorage?.removeItem("cart");
    },
  },
});
export const {
  setActiveAccount,
  setSatStatus,
  setSelectedItems,
  setRandomSelectedItems,
  clearSelectedItems,
} = generalSlice.actions;
export default generalSlice.reducer;
