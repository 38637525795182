import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import Header from "../components/Header";
import Dashboard from "../components/Dashboard";
import HeroInfo from "../assets/images/hero_info.png";
import { useEffect } from "react";

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBonus,
    onOpen: onBonusOpen,
    onClose: onBonusClose,
  } = useDisclosure();

  useEffect(() => {
    let ls = localStorage.getItem("isClaimed");
    if (!ls) {
      onBonusOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box px="20px">
      <Header
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isBonus={isBonus}
        onBonusClose={onBonusClose}
      />
      <Flex mt={{ base: "35px", xl: "50px" }} justify="center">
        <Image src={HeroInfo} alt="gameinfo" maxH="40px" />
      </Flex>
      <Dashboard onOpen={onOpen} />
    </Box>
  );
}

export default Home;
