import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CartListItem from "./CartListItem";
import { useEffect, useState } from "react";
import InscribeIco from "../assets/images/inscribe-ico.svg";
import NoAsset from "../assets/images/no-asset.svg";
import CancelIco from "../assets/images/cancel-ico.svg";

import {
  useGetNetworkFee,
  useGetPaymentInfo,
  usePaymentInfo,
  useRefereshSats,
} from "../utils/sats.api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { estimateNetworkFee } from "../utils/handlers";
import { clearSelectedItems } from "../store/slices/generalSlice";
import { satbox } from "../utils/box/newSat";

function Drawer(props: any) {
  const dispatch = useDispatch();

  const { selectedItems, activeAccount, satStatus } = useSelector(
    (state: any) => state.general
  );
  const { data: networkFee } = useGetNetworkFee();
  const getPaymentInfo = useGetPaymentInfo();
  const refereshMints = useRefereshSats();
  const callbackPaymentInfo = usePaymentInfo();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [estimatedNetworkFee, setEstimatedNetworkFee] = useState<number>(0);
  const [satsInsc, setSatsInsc] = useState(546);
  const [activeFee, setActiveFee] = useState("medium");
  const [checkout, setCheckout] = useState(false);
  const [feeLoading, setFeeLoading] = useState(false);
  const [isSatsInsc, setIsSatInsc] = useState(false);
  const [isInscribeLoading, setIsInscribeLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [dontShow, setDontShow] = useState(true);

  const getEstimateFee = async () => {
    const results = await Promise.all(
      selectedItems?.map(async (number: string) => {
        const satNumebr = satbox(Number(number));
        return satNumebr?.value?.toString();
      })
    );
    try {
      setFeeLoading(true);
      estimateNetworkFee({
        sats: results,
        balance: satsInsc,
        feeRate:
          activeFee === "medium"
            ? networkFee?.data?.halfHourFee
            : networkFee?.data?.fastestFee,
        receiveAddresses: activeAccount?.address,
      }).then((res: number) => {
        setFeeLoading(false);
        setEstimatedNetworkFee(res);
      });
    } catch (e: any) {
      setFeeLoading(false);
      toast.warn(
        e || "Something went wrong, Reload page to calculate fee again!"
      );
    }
  };

  const checkIsUserAgreed = () => {
    const checkIsAgreed = localStorage.getItem("isAgreed");
    if (checkIsAgreed) {
      const ls = JSON.parse(checkIsAgreed);
      if (ls) {
        setIsAgreed(true);
      } else {
        setIsAgreed(false);
      }
    } else {
      setIsAgreed(false);
    }
  };

  const completeTransaction = (txId: any, id: any) => {
    toast.success(`Your TxId is ${txId}`, {
      autoClose: false,
    });
    callbackPaymentInfo
      .mutateAsync({ id: id, txid: txId })
      .then(async (response: any) => {
        setIsInscribeLoading(false);
        console.log("---------response2", response);
        toast.success("Your inscription was successfull!");
        setCheckout(false);
        props.setShowDrawer(false);
        dispatch(clearSelectedItems([]));
        toast.warn("Cart cleared!");
        props?.getRaritySats();
        refereshMints?.mutateAsync({ id, txId });
        for (let i = 0; i < 6; i++) {
          await new Promise((resolve) => setTimeout(resolve, 5000)); // 5000 milliseconds = 5 seconds
          callbackPaymentInfo.mutateAsync({
            id: id,
            txid: txId,
          });
        }
      })
      .catch((err: any) => {
        setIsInscribeLoading(false);
        console.log("----error3", err);
        toast.warn(err?.message || "Something went wrong. Please try again!");
      });
  };

  useEffect(() => {
    getEstimateFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFee, networkFee?.data, satsInsc, selectedItems]);

  useEffect(() => {
    checkIsUserAgreed();
  }, []);

  return (
    <Flex
      flexDir="column"
      justify="space-between"
      minH="100vh"
      maxH="100vh"
      bg="#1C1022"
      minW={{ base: "200px", md: "340px" }}
      p="32px 24px"
      pos="absolute"
      top="0"
      right="0"
      overflowY="scroll"
      zIndex="100"
    >
      <Box>
        <Flex align="center" gap="16px">
          <Text
            fontSize="24px"
            fontWeight="600"
            bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
            bgClip="text"
          >
            RuneBox Cart
          </Text>
          <Box p="8px" borderRadius="45px" bg="#381E45" minW="83px">
            <Text
              color="#c99cff"
              fontSize="16px"
              fontWeight="500"
              textAlign="center"
            >
              {selectedItems?.length}
            </Text>
          </Box>
        </Flex>
        {selectedItems?.length ? (
          <>
            {checkout && (
              <Flex mt="40px" align="center" justify="center" flexDir="column">
                <Box
                  bg="#fff"
                  borderRadius="4px"
                  height="40px"
                  width="40px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image src={InscribeIco} alt="inscribe" />
                </Box>
                <Text
                  bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                  bgClip="text"
                  fontSize="24px"
                  fontWeight="600"
                >
                  Inscribe
                </Text>
              </Flex>
            )}
            <Flex
              align="center"
              flexDir="column"
              mt="27px"
              gap="10px"
              maxH={checkout ? "calc(100vh - 590px)" : "calc(100vh - 260px)"}
              overflowY="scroll"
            >
              {selectedItems?.map((number: any, idx: number) => {
                return (
                  <CartListItem idx={idx} number={number} checkout={checkout} />
                );
              })}
            </Flex>
            {checkout && (
              <Box>
                <Box
                  mt="14px"
                  bg="#1A1A1A"
                  borderRadius="4px"
                  border="1px solid #cd23e9"
                  p="16px"
                >
                  <Text color="#fff" fontSize="18px" fontWeight="400">
                    Select Network Fee
                  </Text>
                  <Flex align="center" gap="8px" mt="12px">
                    <Button
                      bg={
                        activeFee === "medium"
                          ? "rgba(83, 54, 133, 0.70)"
                          : "rgba(83, 54, 133, 0.30)"
                      }
                      borderRadius="5px"
                      color="#fff"
                      fontSize="12px"
                      fontWeight="400"
                      _hover={{
                        bg: "rgba(83, 54, 133, 0.90)",
                      }}
                      onClick={() => {
                        setActiveFee("medium");
                      }}
                    >
                      Medium
                    </Button>
                    <Button
                      bg={
                        activeFee === "fast"
                          ? "rgba(83, 54, 133, 0.70)"
                          : "rgba(83, 54, 133, 0.30)"
                      }
                      borderRadius="5px"
                      color="#fff"
                      fontSize="12px"
                      fontWeight="400"
                      _hover={{
                        bg: "rgba(83, 54, 133, 0.90)",
                      }}
                      onClick={() => {
                        setActiveFee("fast");
                      }}
                    >
                      Fast
                    </Button>
                  </Flex>
                  <Text color="#fff" fontSize="12px" fontWeight="400" mt="12px">
                    {activeFee === "fast"
                      ? Number(networkFee?.data?.fastestFee)?.toLocaleString()
                      : Number(
                          networkFee?.data?.halfHourFee
                        )?.toLocaleString() || 0}{" "}
                    sats/bytes
                  </Text>
                </Box>
                {feeLoading ? (
                  <Flex mt="25px" justify="center">
                    <Spinner size="md" color="#fff" />
                  </Flex>
                ) : (
                  <>
                    <Flex mt="12px" align="center" justify="space-between">
                      <Text color="#fff" fontSize="12px" fontWeight="400">
                        Sats In Inscription:{" "}
                        {Number(
                          selectedItems?.length * Number(satsInsc)
                        )?.toLocaleString()}{" "}
                        sats
                      </Text>
                      <Flex align="center" gap="5px">
                        <Text color="#fff" fontSize="12px" fontWeight="400">
                          ~ $
                          {(
                            (selectedItems?.length *
                              Number(satsInsc) *
                              satStatus?.btc_price) /
                            100000000
                          )?.toFixed(2) || 0}
                        </Text>
                        <Box
                          p="5px"
                          bg="rgba(255, 255, 255, 0.25)"
                          cursor="pointer"
                          borderRadius="6px"
                          onClick={() => setIsSatInsc(!isSatsInsc)}
                        >
                          <Text fontSize="10px" fontFamily="Inter" color="#fff">
                            Customize
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    {isSatsInsc && (
                      <Flex align="center" mt="8px" gap="20px">
                        <Slider
                          aria-label="slider-ex-4"
                          defaultValue={satsInsc}
                          max={10000}
                          min={330}
                          onChange={(e) => setSatsInsc(e)}
                          mt="10px"
                        >
                          <SliderTrack bg="#533685" height="8px">
                            <SliderFilledTrack bg="#321F4A" />
                          </SliderTrack>
                          <SliderThumb
                            boxSize={6}
                            bg="#533685"
                            h="15px"
                            width="30px"
                            borderRadius="0"
                          ></SliderThumb>
                        </Slider>
                        <Input
                          border="2px solid rgba(83, 54, 133, 0.40) !important"
                          w="176px"
                          bg="transparent"
                          outline="none"
                          textAlign="center"
                          color="#fff"
                          fontSize="12px"
                          fontWeight="400"
                          value={satsInsc}
                          onChange={(e) => setSatsInsc(Number(e.target.value))}
                        />
                      </Flex>
                    )}
                    <Flex mt="8px" justify="space-between">
                      <Text color="#fff" fontSize="12px" fontWeight="400">
                        Network Fee :{" "}
                        {Number(estimatedNetworkFee)?.toLocaleString() || 0}{" "}
                        sats
                      </Text>
                      <Text color="#fff" fontSize="12px" fontWeight="400">
                        ~ $
                        {(
                          (estimatedNetworkFee * satStatus?.btc_price) /
                          100000000
                        )?.toFixed(2) || 0}
                      </Text>
                    </Flex>
                    <Flex mt="8px" justify="space-between">
                      <Text color="#fff" fontSize="12px" fontWeight="400">
                        Deed Fee:{" "}
                        {Number(
                          Number(satStatus?.service_fee) * selectedItems?.length
                        )?.toLocaleString() || 0}{" "}
                        sats
                      </Text>
                      <Text color="#fff" fontSize="12px" fontWeight="400">
                        ~ $
                        {(
                          (Number(satStatus?.service_fee) *
                            selectedItems?.length *
                            satStatus?.btc_price) /
                          100000000
                        )?.toFixed(2) || 0}
                      </Text>
                    </Flex>
                    <Flex mt="24px" justify="space-between">
                      <Text color="#fff" fontSize="15px" fontWeight="500">
                        Estimated Total:{" "}
                        {activeFee === "medium"
                          ? Number(
                              Number(networkFee?.data?.halfHourFee || 0) +
                                estimatedNetworkFee +
                                satsInsc +
                                Number(satStatus?.service_fee) *
                                  selectedItems?.length
                            )?.toLocaleString()
                          : Number(
                              Number(networkFee?.data?.fastestFee || 0) +
                                estimatedNetworkFee +
                                satsInsc +
                                Number(satStatus?.service_fee) *
                                  selectedItems?.length
                            )?.toLocaleString()}{" "}
                        sats
                      </Text>
                      <Text color="#fff" fontSize="12px" fontWeight="500">
                        ~ $
                        {activeFee === "medium"
                          ? (
                              ((Number(networkFee?.data?.halfHourFee || 0) +
                                estimatedNetworkFee +
                                satsInsc +
                                satStatus?.service_fee *
                                  selectedItems?.length) *
                                satStatus?.btc_price) /
                              100000000
                            )?.toFixed(2) || 0
                          : (
                              ((Number(networkFee?.data?.fastestFee || 0) +
                                estimatedNetworkFee +
                                satsInsc +
                                satStatus?.service_fee *
                                  selectedItems?.length) *
                                satStatus?.btc_price) /
                              100000000
                            )?.toFixed(2) || 0}{" "}
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            )}
          </>
        ) : (
          <AbsoluteCenter>
            <Image src={NoAsset} alt="no asset" />
          </AbsoluteCenter>
        )}
      </Box>

      <Box>
        {!checkout && (
          <Flex mt="10px" gap="10px" align="center">
            <Text color="#FFC700" cursor="pointer" onClick={onOpen}>
              Disclaimer:
            </Text>
            <Checkbox
              isChecked={isAgreed}
              onChange={(e) => {
                onOpen();
              }}
            />
            <Text color="#fff" cursor="pointer" onClick={onOpen}>
              I Agree
            </Text>
          </Flex>
        )}
        <Flex
          gap="10px"
          flexDir={{ base: "column", md: "row" }}
          justify="center"
          align="center"
          mt="10px"
        >
          {checkout ? (
            <Button
              bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              borderRadius="6px"
              p="10px"
              w="254px"
              h="52px"
              color="#fff"
              fontSize="18px"
              fontWeight="600"
              _hover={{
                background: "transparent",
                border: "2px solid #CD23E9",
              }}
              isDisabled={
                isInscribeLoading || feeLoading || selectedItems?.length < 1
              }
              onClick={async () => {
                if (satsInsc < 330) {
                  toast.warn(`Sats in inscription can't be less than 330`);
                } else {
                  let inscribePayload = {
                    sendAddress: activeAccount?.address,
                    receiveAddress: activeAccount?.address,
                    sats: selectedItems,
                    feeRate:
                      activeFee === "fast"
                        ? networkFee?.data?.fastestFee
                        : networkFee?.data?.halfHourFee,
                    outputValue: satsInsc,
                  };
                  try {
                    setIsInscribeLoading(true);
                    getPaymentInfo
                      .mutateAsync(inscribePayload)
                      .then(async (res: any) => {
                        console.log("----------response1", res);
                        if (activeAccount?.wallet === "Unisat") {
                          window.unisat
                            .sendBitcoin(
                              res?.data?.payAddress,
                              res?.data?.amount
                            )
                            .then((txId: any) => {
                              completeTransaction(txId, res?.data?.id);
                            })
                            .catch((err: any) => {
                              toast.warn(
                                err?.message ||
                                  "Something went wrong, Please try again!"
                              );
                            });
                        } else if (activeAccount?.wallet === "OKX") {
                          window.okxwallet?.bitcoin
                            ?.sendBitcoin(
                              res?.data?.payAddress,
                              res?.data?.amount
                            )
                            .then((txId: any) => {
                              completeTransaction(txId, res?.data?.id);
                            })
                            .catch((err: any) => {
                              toast.warn(
                                err?.message ||
                                  "Something went wrong, Please try again!"
                              );
                            });
                        } else {
                          toast.warn("Something Went wrong, Please try again!");
                        }
                      })
                      .catch((err: any) => {
                        console.log("----error2", err);
                        setIsInscribeLoading(false);
                        toast.warn(
                          err?.message ||
                            "Something went wrong. Please try again!"
                        );
                      });
                  } catch (err: any) {
                    console.log("----error1", err);
                    setIsInscribeLoading(false);
                    toast.warn(
                      err?.message || "Something went wrong. Please try again!"
                    );
                  }
                }
              }}
            >
              {isInscribeLoading ? (
                <Spinner size="sm" color="#fff" />
              ) : (
                "Inscribe"
              )}
            </Button>
          ) : (
            <Button
              bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              borderRadius="6px"
              p="10px"
              w="254px"
              h="52px"
              color="#fff"
              fontSize="18px"
              fontWeight="600"
              _hover={{
                background: "transparent",
                border: "2px solid #CD23E9",
              }}
              _disabled={{
                opacity: "0.6",
              }}
              isDisabled={selectedItems?.length < 1}
              onClick={() => {
                if (activeAccount?.address) {
                  setCheckout(true);
                  getEstimateFee();
                } else if (!isAgreed) {
                  onOpen();
                } else {
                  props?.onOpen();
                }
              }}
            >
              Checkout
            </Button>
          )}
          <Button
            borderRadius="6px"
            bg="transparent"
            border={{ base: "0", md: "1px solid #CD23E9" }}
            h="52px"
            fontSize="18px"
            fontWeight="600"
            color={{ base: "#fff", md: "#CD23E9" }}
            _hover={{
              background: "#CD23E9",
              color: "#fff",
            }}
            onClick={() => {
              setCheckout(false);
              props.setShowDrawer(false);
            }}
          >
            Cancel
          </Button>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#1C1022" minW={{ base: "320px", md: "524px" }}>
          <ModalHeader
            fontSize="18px"
            fontWeight="600"
            color="#FFC700"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Disclaimer
            <Box cursor="pointer" onClick={onClose}>
              <Image src={CancelIco} alt="cancel" />
            </Box>
          </ModalHeader>
          <ModalBody mb="20px" fontFamily="Inter">
            <Text color="#EDECEC" fontSize="14px" fontWeight="400">
              The accuracy or timeliness of data derived from the mempool or
              blockchain cannot be guaranteed. It is strongly recommended to
              cross-verify with other indexers for confirmation and accuracy.
            </Text>
            <Flex mt="20px" justify="end" gap="10px">
              <Checkbox
                isChecked={dontShow}
                onChange={(e: any) => setDontShow(e.target.checked)}
              />
              <Text color="#fff">Don't show me again!</Text>
            </Flex>
            <Button
              mt="20px"
              w="100%"
              bg="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
              color="#fff"
              p="20px 10px"
              _hover={{
                bg: "transparent",
                border: "1px solid #CD23E9",
              }}
              onClick={() => {
                if (dontShow) {
                  localStorage.setItem("isAgreed", JSON.stringify(true));
                }
                setIsAgreed(true);
                onClose();
              }}
            >
              I Agree
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Drawer;
