import { Box } from "@chakra-ui/react";

function Value(props: any) {
  return (
    <Box fontSize="14px" fontWeight="400" color="#fff">
      {props.value}
    </Box>
  );
}

export default Value;
