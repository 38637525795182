import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import SearchBlock from "./SearchBlock";
import FilterIco from "../assets/images/filter.svg";

function MyItemsFilter(props: any) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tempActive, setTempActive] = useState("all");

  useEffect(() => {
    setTempActive(props.activeTab);
  }, [props.activeTab]);

  return (
    <>
      <Flex
        p={{ base: "8px", md: "20px" }}
        align="center"
        justify="space-between"
        gap="20px"
        mt={{ base: "10px", sm: "0px" }}
      >
        <Flex gap="20px" w="100%" display={{ base: "none", xl: "flex" }}>
          {props?.tabItems.map((item: string, index: number) => (
            <Box
              key={index}
              onClick={() => {
                props.setActiveTab(item);
              }}
              cursor="pointer"
            >
              <Text
                fontSize="16px"
                fontWeight={props.activeTab === item ? "600" : "500"}
                color={"#715693"}
                background={
                  props.activeTab === item
                    ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                    : ""
                }
                bgClip={props.activeTab === item ? "text" : ""}
                textTransform="capitalize"
              >
                {item}
              </Text>
              {props.activeTab === item && (
                <Box
                  mt="8px"
                  height="4px"
                  width="22px"
                  borderRadius="24px"
                  background="linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                ></Box>
              )}
            </Box>
          ))}
        </Flex>

        <Flex
          gap="8px"
          w={{ base: "100%", xl: "auto" }}
          align="center"
          justify="center"
        >
          <SearchBlock />
          <Box
            pos="relative"
            width="56px"
            height="52px"
            bg="#000"
            borderRadius="8px"
            cursor="pointer"
            display={{ base: "block", xl: "none" }}
            onClick={() => setIsFilterOpen(true)}
          >
            <AbsoluteCenter>
              <Image src={FilterIco} alt="cart" />
            </AbsoluteCenter>
          </Box>
        </Flex>
      </Flex>
      <Sheet
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{
            background: "#180530",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <Sheet.Header />
          <Sheet.Content style={{ padding: "0px 24px 24px 24px" }}>
            <Text
              color="#fff"
              fontSize="20px"
              fontWeight="600"
              lineHeight="32px"
            >
              Filter
            </Text>
            <Text
              mt="12px"
              color="#fff"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="32px"
            >
              Select Asset
            </Text>
            <Flex mt="8px" gap="6px" flexWrap="wrap" bg="transparent">
              {props?.tabItems?.map((tab: string, idx: number) => (
                <Box
                  key={idx}
                  p="2px 32px"
                  border="1px solid #CD23E9"
                  borderRadius="30px"
                  bg={
                    tempActive === tab
                      ? "linear-gradient(97deg, #CD23E9 44.31%, #AC00FD 90.84%)"
                      : "transparent"
                  }
                  cursor="pointer"
                  onClick={() => {
                    setTempActive(tab);
                  }}
                >
                  <Text
                    color={tempActive === tab ? "#fff" : "#9B88BC"}
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="32px"
                    textTransform="capitalize"
                  >
                    {tab}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Button
              mt="28px"
              color="#fff"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="16px"
              height="48px"
              padding="16px 0px"
              borderRadius="48px"
              maxW="450px"
              width="100%"
              marginLeft="auto"
              marginRight="auto"
              bg="#8259C8"
              _hover={{
                background: "transparent",
                border: "2px solid #8259c8",
              }}
              onClick={() => {
                props.setActiveTab(tempActive);
                setIsFilterOpen(false);
              }}
            >
              Apply
            </Button>
            <Button
              mt="8px"
              color="#D0CECE"
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="500"
              lineHeight="16px"
              height="48px"
              padding="16px 0px"
              borderRadius="48px"
              maxW="450px"
              width="100%"
              marginLeft="auto"
              marginRight="auto"
              bg="transparent"
              _hover={{
                background: "transparent",
                border: "2px solid #8259c8",
              }}
              onClick={() => setIsFilterOpen(false)}
            >
              Cancel
            </Button>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default MyItemsFilter;
